import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { TextField } from '@one-thd/sui-atomic-components';
import { notEmptyString, validQA } from '../helper/QAModalFormHelper';

export const QAField = ({
  text,
  placeholder,
  value,
  validationError,
  setQAText
}) => {
  const lowerText = text.toLowerCase();

  const [errorTextForQA, setErrorTextForQA] = useState(null);
  const [status, setStatus] = useState(null);

  const handleChange = (event) => {
    const answer = event.target.value;
    if (!notEmptyString(answer)) {
      setErrorTextForQA(`Please provide a ${lowerText}.`);
      setStatus('error');
    } else if (!validQA(answer)) {
      setErrorTextForQA(`Please enter a valid ${lowerText}.`);
      setStatus('error');
    } else {
      setErrorTextForQA(null);
      setStatus('success');
    }
    setQAText(answer);
  };

  return (
    <div className="sui-mb-6 sui-px-3">
      <TextField
        data-testid="textArea"
        fullWidth
        InputProps={{ inputProps: { maxLength: '255' } }}
        label={`Your ${text}`}
        minRows={5}
        maxRows={5}
        multiline
        onChange={handleChange}
        placeholder={placeholder}
        required
        status={validationError ? 'error' : status}
        statusMessage={validationError || errorTextForQA}
        value={value}
      />
    </div>
  );
};

QAField.displayName = 'QAField';

QAField.propTypes = {
  text: string,
  placeholder: string,
  value: string,
  validationError: string,
  setQAText: func
};

QAField.defaultProps = {
  text: '',
  placeholder: '',
  validationError: '',
  value: '',
  setQAText: null
};
