import React, { useState, useContext } from 'react';
import { arrayOf, string } from 'prop-types';
import { Link } from '@one-thd/sui-atomic-components';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import QAContext from '../../../context/QAContext';
import { triggerAnalytics } from '../../../Analytics';
import { OverlayPage } from '../../OverlayPage/OverlayPage';
import { BadgeDefinitions } from './BadgeDefinitions/BadgeDefinitions';

export const UserBadges = ({ badges }) => {
  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';

  const badgeToClassConverter = (type) => {
    const classes = {
      DIY: 'diy',
      PRO: 'toolbox',
      TOP1: '1',
      TOP10: '10',
      TOP25: '25',
      TOP50: '50',
      TOP100: '100',
      TOP250: '250',
      TOP500: '500',
      TOP1000: '1k',
      EXPERT: 'star-outline',
      COMMUNITYASSOCIATE: '',
      STAFF: 'associate',
      INCENTIVIZEDREVIEW: 'community',
      EARLYREVIEWERINCENTIVE: 'early_reviewer_program',
    };
    return classes[type];
  };

  const badgeToTextConverter = (type) => {
    const classes = {
      DIY: 'DIY',
      PRO: 'Pro',
      TOP1: '#1 Contributor',
      TOP10: 'Top 10 Contributor',
      TOP25: 'Top 25 Contributor',
      TOP50: 'Top 50 Contributor',
      TOP100: 'Top 100 Contributor',
      TOP250: 'Top 250 Contributor',
      TOP500: 'Top 500 Contributor',
      TOP1000: 'Top 1000 Contributor',
      TOP1CONTRIBUTOR: '#1 Contributor',
      TOP10CONTRIBUTOR: 'Top 10 Contributor',
      TOP25CONTRIBUTOR: 'Top 25 Contributor',
      TOP50CONTRIBUTOR: 'Top 50 Contributor',
      TOP100CONTRIBUTOR: 'Top 100 Contributor',
      TOP250CONTRIBUTOR: 'Top 250 Contributor',
      TOP500CONTRIBUTOR: 'Top 500 Contributor',
      TOP1000CONTRIBUTOR: 'Top 1000 Contributor',
      EXPERT: 'Expert',
      COMMUNITYASSOCIATE: 'Community Associate',
      STAFF: 'Home Depot Associate',
      INCENTIVIZEDREVIEW: 'Seed Program Review',
      EARLYREVIEWERINCENTIVE: 'The Home Depot Reviewer Program',
    };
    return classes[type];
  };

  const badgeLinks = {
    INCENTIVIZEDREVIEW: 'https://www.homedepot.com/c/home_depot_seeds_program',
    EARLYREVIEWERINCENTIVE: 'https://www.homedepot.com/c/the_home_depot_reviewer_program'
  };

  const [overlayPageOpen, setOverlayPageOpen] = useState(false);
  const [overlayPageContent, setOverlayPageContent] = useState(BadgeDefinitions);

  const analyticsClickBadge = () => {
    const payload = { component: 'badge' };
    triggerAnalytics(payload);
  };

  const openOverlayPage = () => {
    analyticsClickBadge();
    setOverlayPageOpen(true);
  };

  const closeOverlayPage = () => {
    setOverlayPageOpen(false);
  };

  return (
    <>
      <ul className={!isMobile ? 'sui-list-none sui-flex sui-flex-wrap' : 'sui-list-none'}>
        {badges.map((badge, index) => (
          <li key={`badge_${index}`} className={!isMobile ? 'sui-pr-3' : ''}>
            <button
              type="button"
              onClick={openOverlayPage}
              className="sui-flex sui-flex-wrap sui-items-center sui-pb-2"
            >
              <span className={`badge-icons--${badgeToClassConverter(badge.toUpperCase())} sui-mr-2`} />
              <span className="sui-text-left sui-text-base">
                {badgeLinks[badge.toUpperCase()] ? (
                  <Link
                    href={badgeLinks[badge.toUpperCase()]}
                    target="_blank"
                    rel="noopener noreferrer"
                    underline="always"
                  >
                    {badgeToTextConverter(badge.toUpperCase())}
                  </Link>
                ) : badgeToTextConverter(badge.toUpperCase())}
              </span>
            </button>
          </li>
        ))}
      </ul>
      <OverlayPage
        overlayPageTitle="Home Depot Contributor Badges"
        OverlayPageContent={overlayPageContent}
        overlayPageOpen={overlayPageOpen}
        closeOverlayPage={closeOverlayPage}
      />
    </>
  );
};

UserBadges.displayName = 'UserBadges';

UserBadges.propTypes = {
  badges: arrayOf(string),
  // channel: string
};

UserBadges.defaultProps = {
  badges: [],
  // channel: ''
};
