import React from 'react';
import {
  func, oneOf, shape, string, symbol
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { Checkmark } from '@one-thd/sui-icons';

export const AfterSubmitMessage = (
  {
    color,
    icon: Icon,
    message,
  }) => {
  return (
    <div className="sui-flex sui-mt-4 sui-items-center">
      {/* eslint-disable-next-line max-len */}
      <div className={`sui-flex sui-justify-center sui-items-center sui-rounded-full sui-shrink-0 sui-w-6 sui-h-6 sui-bg-${color} sui-mr-2`}>
        <Icon color="inverse" size="small" />
      </div>
      <Typography variant="body-small" height="tight" color={color}>{message}</Typography>
    </div>
  );
};

AfterSubmitMessage.displayName = 'AfterSubmitMessage';

AfterSubmitMessage.propTypes = {
  color: oneOf(['success', 'danger', 'warning', 'info']),
  icon: shape({
    $$typeof: symbol,
    render: func,
  }),
  message: string,
};

AfterSubmitMessage.defaultProps = {
  color: 'success',
  icon: Checkmark,
  message: 'Thank you. Your feedback helps us improve your online shopping experience.',
};