import React, { useContext } from 'react';
import { number } from 'prop-types';
import { PaginationDisplayedItemCount, } from '@one-thd/sui-atomic-components';
import QAContext from '../../context/QAContext';
import { QUESTION_DISPLAY_PER_PAGE } from '../../constants';

import './PagerDisplayText.scss';

export const PagerDisplayText = ({ seoTotalResults }) => {
  const { currentPage, TotalResults, channel } = useContext(QAContext);

  const isMobile = channel === 'mobile';

  const results = TotalResults || seoTotalResults;

  if (!results) {
    return (
      <span className="sui-font-regular sui-text-base sui-text-primary sui-tracking-normal
      sui-normal-case sui-line-clamp-unset sui-grow-0 sui-mr-4"
      >No results found
      </span>
    );
  }

  return (
    <div className="sui-mr-4">
      <PaginationDisplayedItemCount
        disableGrow={!isMobile}
        page={currentPage}
        itemsPerPage={QUESTION_DISPLAY_PER_PAGE}
        items={results}
        data-testid="pager-display-text"
      />
    </div>
  );
};
PagerDisplayText.displayName = 'PagerDisplayText';
PagerDisplayText.propTypes = {
  seoTotalResults: number
};
PagerDisplayText.defaultProps = {
  seoTotalResults: null
};