import React, { useContext } from 'react';
import { bool, number } from 'prop-types';
import { Search } from '../../Filter/index';
import { AskAQuestion } from '../../AskAQuestion/AskAQuestion.component';
import QAContext from '../../../context/QAContext';

export const Header = ({ showTitleWithTotalQns, seoData }) => {
  let { channel, TotalResults, seoPageNumber } = useContext(QAContext);

  if (seoPageNumber && seoData) TotalResults = seoData;

  return (
    <div className="sui-w-full sui-mb-4">
      <div className="sui-w-full">
        <Search nopadding />
      </div>
    </div>
  );
};

Header.displayName = 'Header';

Header.propTypes = {
  showTitleWithTotalQns: bool,
  seoData: number
};

Header.defaultProps = {
  showTitleWithTotalQns: true,
  seoData: null
};
