import React, { useEffect, useContext } from 'react';
import { Dropdown, MenuItem } from '@one-thd/sui-atomic-components';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import './Filter.scss';

export const Sort = () => {
  const { searchText, sortBy, dispatch, channel } = useContext(QAContext);

  const isMobile = channel === 'mobile';

  const sortOptions = [
    { name: 'Newest Questions', value: 'newest' },
    { name: 'Oldest Questions', value: 'oldest' },
    { name: 'Most Answered', value: 'totalanswercount' },
    { name: 'Most Helpful', value: 'mosthelpfull' },
    { name: 'Featured Questions', value: 'featuredreview' },
    { name: 'Can you answer these questions', value: 'leastanswers' }
  ];

  const disable = searchText !== '';

  const onSortChange = (event) => {
    let { value } = event.target;

    dispatch({ action: 'SET_SORT_BY', value });
    dispatch({ action: 'SET_CURRENT_PAGE', value: 1 });

    const selectedIndex = sortOptions.findIndex(
      (index) => index.value === value
    );

    const payload = {
      component: 'sort by',
      element: sortOptions[selectedIndex].name.toLowerCase()
    };

    triggerAnalytics(payload);
  };

  return (
    isMobile ? (
      <div
        className={`sui-flex-initial sui-w-full sui-ml-auto ${disable ? 'sui-disabled:opacity-50' : ''}`}
        data-testid="sort-box"
      >
        <Dropdown maxItems={3} onChange={onSortChange} value={sortBy} disabled={disable}>
          <MenuItem value="newest">Newest</MenuItem>
          <MenuItem value="oldest">Oldest Questions</MenuItem>
          <MenuItem value="totalanswercount">Most Answered</MenuItem>
          <MenuItem value="mosthelpfull">Most Helpful</MenuItem>
          <MenuItem value="featuredreview">Featured Questions</MenuItem>
          <MenuItem value="leastanswers">Can you answer these questions</MenuItem>
        </Dropdown>
      </div>
    ) : (
      <div
        className={`sui-flex-initial sui-w-1/3 sui-ml-auto ${disable ? 'sui-disabled:opacity-50' : ''}`}
        data-testid="sort-box"
      >
        <Dropdown maxItems={3} onChange={onSortChange} value={sortBy} disabled={disable}>
          <MenuItem value="newest">Newest</MenuItem>
          <MenuItem value="oldest">Oldest Questions</MenuItem>
          <MenuItem value="totalanswercount">Most Answered</MenuItem>
          <MenuItem value="mosthelpfull">Most Helpful</MenuItem>
          <MenuItem value="featuredreview">Featured Questions</MenuItem>
          <MenuItem value="leastanswers">Can you answer these questions</MenuItem>
        </Dropdown>
      </div>
    )
  );
};

Sort.displayName = 'QuestionsAndAnswerSort';
