import {
  arrayOf as arrayType,
  params,
  string as stringType,
  bool as booleanType,
  shape as shapeType,
  customType
} from '@thd-nucleus/data-sources';

const BVResponse = stringType();

export const dataModel = {
  captureFeedback: params({
    feedback: customType('FeedbackInput').shape({
      visitorId: stringType()
        .isRequired(),
      anchorKey: stringType(),
      anchorType: stringType(),
      customerInsights: stringType()
        .isRequired(),
      content: shapeType({
        questionAndAnswer: shapeType({
          question: stringType(),
          answer: stringType(),
        })
      })
        .isRequired(),
      feedbackType: customType('FeedbackType').enum(['POSITIVE', 'NEGATIVE'])
        .isRequired(),
      feedbackComment: stringType(),
      feedbackReason: stringType(),
      modelName: stringType(),
      storeId: stringType(),
      zipCode: stringType()
    }).isRequired()
  }).mutation()
    .shape(BVResponse),
};
