import React, { useEffect, useContext } from 'react';
import { useConfigService } from '@thd-nucleus/experience-context';
import { AccordionBodyPlaceholder } from './AccordionBodyPlaceholder';
import { QAResultContent } from '../QAResultContent/QAResultContent';
import { NoContent } from '../NoContent/NoContent.component';
import { PostAQuestion } from '../MagicApron/PostAQuestion';
import { useQAResult } from '../QAResult/useQAResult';
import { useSEO } from '../QAResult/useSEO';
import QAContext from '../../context/QAContext';
import { dataModel } from '../dataModel';
import { Pager } from '../Pager/Pager.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { triggerAnalytics } from '../../Analytics';
import { QAResultHeader } from '../QAResult/QAResultHeader';
import '../QAResult/QAResult.scss';

const AccordionBodyComponent = () => {
  useEffect(() => { triggerAnalytics('accordion-open'); }, []);
  const magicApronBotEnabled = false;

  const { TotalResults, qaLoading, searchText } = useContext(QAContext);
  const qaResult = useQAResult();
  const { seoResults, seoTotalResults, seoAnswers } = useSEO(qaResult);

  if (qaLoading) return <AccordionBodyPlaceholder />;

  const resultCount = seoResults ? seoTotalResults : TotalResults;
  const showQAContent = resultCount > 0;
  const showHeader = searchText || showQAContent;
  const showNoContent = !showQAContent;

  return (
    <>
      <QAResultHeader
        showHeader={showHeader}
        seoResults={seoResults}
        seoTotalResults={seoTotalResults}
      />
      <QAResultContent
        showQAContent={showQAContent}
        seoResults={seoResults}
        seoAnswers={seoAnswers}
        seoTotalResults={seoTotalResults}
      />
      {showQAContent
        && (
          <div>
            {magicApronBotEnabled && <PostAQuestion />}
            <Pager seoTotalResults={resultCount} />
            <div className="sui-text-center">
              <PagerDisplayText seoTotalResults={resultCount} />
            </div>
          </div>
        )}
      <NoContent showNoContent={showNoContent} />
    </>
  );
};

AccordionBodyComponent.displayName = 'AccordionBody';

AccordionBodyComponent.propTypes = {};

AccordionBodyComponent.defaultProps = {};

AccordionBodyComponent.dataModel = dataModel;

export { AccordionBodyComponent };
