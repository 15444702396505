import React, { useEffect, useState } from 'react';
import {
  arrayOf,
  bool,
  func,
  shape,
  string
} from 'prop-types';
import { Typography } from '@one-thd/sui-atomic-components';
import { AfterSubmitMessage } from './AfterSubmitMessage';
import { ThumbsUpThumbsDown } from './ThumbsUpThumbsDown';
import { PopoverFeedback } from './PopoverFeedback';
import { useUserFeedback } from '../hooks/useUserFeedback';
import { dataModel } from '../dataModel';

export const ThumbsUpThumbsDownWithPopover = (
  {
    anchorKey,
    anchorType,
    title,
    thumbsUpOrDownFeedbackLabels,
    skipPopover,
    reasons,
    popoverFeedbackLabels,
    customerInsights,
    handleFeedbackData
  }) => {
  const [optionSelected, setOptionSelected] = useState(false);
  const [feedbackSubmitted, setFeedbackSubmitted] = useState(false);
  const [selectedOptionReasons, setSelectedOptionReasons] = useState([]);
  const [showPopover, setShowPopover] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [submitUserFeedback, response, getFeedbackData] = useUserFeedback();

  const submitFeedbackData = (feedback) => {
    const dataToSubmit = {
      ...feedback,
      anchorKey,
      anchorType,
      customerInsights,
      content: handleFeedbackData()
    };
    submitUserFeedback({ variables: getFeedbackData(dataToSubmit) });
    if (response) {
      setFeedbackSubmitted(true);
    }
  };

  const handleSelectOption = (option) => {
    setOptionSelected(option);
  };

  // eslint-disable-next-line consistent-return
  const handleAfterThumbsUpThumbsDownSelected = () => {
    if (reasons[optionSelected].length === 0 || skipPopover) {
      submitFeedbackData({
        feedbackType: optionSelected
      });
      return setFeedbackSubmitted(true);
    }

    setSelectedOptionReasons(reasons[optionSelected]);
    setShowPopover(true);
  };

  const handlePopoverClose = (__) => {
    setShowPopover(false);
    submitFeedbackData({ feedbackType: optionSelected });
    setFeedbackSubmitted(true);
  };

  const handlePopoverSubmit = (data) => {
    submitFeedbackData({ feedbackType: optionSelected, ...data });
    setFeedbackSubmitted(true);
  };

  useEffect(() => {
    if ((optionSelected)) handleAfterThumbsUpThumbsDownSelected();
  }, [optionSelected]);

  return (
    feedbackSubmitted
      ? (
        <AfterSubmitMessage />
      )
      : (
        <>
          <div className="sui-flex sui-items-center sui-mt-4 sui-gap-1">
            <Typography variant="body-base">{title}</Typography>{' '}
            <ThumbsUpThumbsDown
              onClick={handleSelectOption}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...thumbsUpOrDownFeedbackLabels}
              setAnchorEl={setAnchorEl}
            />
          </div>
          {selectedOptionReasons && (
            <PopoverFeedback
              open={showPopover}
              anchorEl={anchorEl}
              reasons={selectedOptionReasons}
              onClose={handlePopoverClose}
              onSubmit={handlePopoverSubmit}
              buttonsBaseId={anchorKey}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...popoverFeedbackLabels}
            />
          )}
        </>
      )
  );
};

ThumbsUpThumbsDownWithPopover.displayName = 'ThumbsUpThumbsDownWithPopover';
ThumbsUpThumbsDownWithPopover.dataModel = dataModel;

ThumbsUpThumbsDownWithPopover.propTypes = {
  anchorKey: string,
  anchorType: string,
  title: string,
  thumbsUpOrDownFeedbackLabels: shape({
    thumbsUpAriaLabel: string,
    thumbsDownAriaLabel: string,
  }),
  skipPopover: bool,
  reasons: shape({
    POSITIVE: arrayOf(string),
    NEGATIVE: arrayOf(string),
  }),
  popoverFeedbackLabels: shape({
    title: string,
    closeButtonAriaLabel: string,
    textAreaPlaceholder: string,
    submitCTA: string,
  }),
  customerInsights: string,
  handleFeedbackData: func.isRequired,
  modelName: string
};

ThumbsUpThumbsDownWithPopover.defaultProps = {
  title: 'Was this helpful?',
  thumbsUpOrDownFeedbackLabels: {},
  skipPopover: false,
  reasons: {
    POSITIVE: [],
    NEGATIVE: [
      'Inaccurate',
      'Irrelevant',
      'Unsafe/Offensive',
      'Other',
    ],
  },
  popoverFeedbackLabels: {},
  anchorKey: null,
  anchorType: null,
  customerInsights: null,
  modelName: null
};
