import React, { useState, useContext } from 'react';
import { InputAdornment, TextField, IconButton } from '@one-thd/sui-atomic-components';
import { Search as SearchIcon } from '@one-thd/sui-icons';
import QAContext from '../../context/QAContext';
import { triggerAnalytics } from '../../Analytics';
import './Filter.scss';

export const Search = () => {
  const { dispatch } = useContext(QAContext);
  const [search, setSearch] = useState('');

  const onSubmit = (event) => {
    event.preventDefault();
    dispatch({ action: 'SET_SEARCH_TEXT', value: search });
    const payload = { component: 'search' };
    triggerAnalytics(payload);
  };

  const handleInputChange = (event) => {
    setSearch(event?.target?.value);
  };

  return (
    <div className="sui-w-full">
      <form onSubmit={onSubmit}>
        <TextField
          onChange={handleInputChange}
          label="Find an Answer"
          placeholder="Type a keyword or phrase"
          fullWidth
          value={search}
          data-testid="search-bar"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton icon={SearchIcon} size="small" onClick={onSubmit} />
              </InputAdornment>
            ),
          }}
        />
      </form>
    </div>
  );
};

Search.displayName = 'QuestionAndAnswerSearch';
