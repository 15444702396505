import React, { useState, useEffect } from 'react';
import { number, string } from 'prop-types';
import { ThumbsUp } from '@one-thd/sui-icons';
import { useSubmitHelpful } from '../../../hooks/index';
import { dataModel } from '../dataModel';
import { triggerAnalytics } from '../../../Analytics';
import '../Voting.scss';

const Vote = (props) => {
  const { totalPositiveFeedbackCount, answerId } = props;

  const [voted, setVoted] = useState(false);

  const [buttonText, setButtonText] = useState('Helpful');

  const postHelpfulData = {
    id: answerId,
    feedbacktype: 'helpfulness',
    contenttype: 'answer',
    vote: 'positive'
  };

  const [submitHelpful, helpfulResponse] = useSubmitHelpful(postHelpfulData);

  const handleHelpful = () => {
    submitHelpful();
    const payload = { component: 'was this helpful', element: 'upvote' };
    triggerAnalytics(payload);
  };

  useEffect(() => {
    if (helpfulResponse.data && !voted) {
      setVoted(true);
      setButtonText('Helpful');
    }
  }, [helpfulResponse.data]);

  return (
    <div className="sui-flex-1 sui-text-left sui-inline-block sui-pb-1">
      <ThumbsUp color="primary" size="small" />
      <button
        className="sui-text-base sui-mx-2 sui-pb-1"
        onClick={handleHelpful}
        title="Helpful"
        disabled={helpfulResponse.called}
        type="button"
      >
        {buttonText}
      </button>
      {totalPositiveFeedbackCount > 0 ? (
        <span className="sui-text-base sui-pb-2" data-testid="positive-feedback-count">
          {`(${voted ? totalPositiveFeedbackCount + 1 : totalPositiveFeedbackCount})`}
        </span>
      ) : (
        <span className="sui-text-base sui-pb-2">
          {voted
            ? `(${totalPositiveFeedbackCount + 1})`
            : null}
        </span>
      )}
    </div>
  );
};

Vote.dataModel = dataModel;

Vote.propTypes = {
  totalPositiveFeedbackCount: number,
  answerId: string.isRequired
};

Vote.defaultProps = {
  totalPositiveFeedbackCount: 0
};

Vote.displayName = 'Vote';

export { Vote };
