import React, { useState, useContext } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import classNames from 'classnames';
import { Button } from '@one-thd/sui-atomic-components';
import { Edit } from '@one-thd/sui-icons';
import { QAModal } from '../QAModalForm/QAModalForm.component';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import './AskAQuestion.scss';

export const AskAQuestion = () => {
  const { channel, itemId, TotalResults, searchText } = useContext(QAContext);

  const [askAQnOverlayOpen, setAskAQnOverlayOpen] = useState(false);

  const isMobile = channel === 'mobile';

  const buttonText = TotalResults === 0 && !searchText ? 'Ask the First Question' : 'Ask a Question';

  const handleOnClick = () => {
    setAskAQnOverlayOpen(true);

    const payload = { component: 'ask a new question' };
    triggerAnalytics(payload);
  };

  return (
    isMobile ? (
      <>
        <div className="sui-w-full sui-block sui-mb-6 sui-my-4 sui-font-bold">
          <Button variant="secondary" startIcon={Edit} onClick={handleOnClick}>
            {buttonText}
          </Button>
        </div>
        {askAQnOverlayOpen && (
          <QueryProvider cacheKey="ask-a-question-form">
            <QAModal
              itemId={itemId}
              channel={channel}
              openModal={askAQnOverlayOpen}
              headerText="Ask a Question"
              closeModal={() => setAskAQnOverlayOpen(false)}
            />
          </QueryProvider>
        )}
      </>
    ) : (
      <>
        <div className="sui-w-fit sui-block sui-mb-6 sui-my-4 sui-font-bold">
          <Button variant="secondary" startIcon={Edit} onClick={handleOnClick} data-testid="ask-a-question">
            {buttonText}
          </Button>
        </div>
        {askAQnOverlayOpen && (
          <QueryProvider cacheKey="ask-a-question-form">
            <QAModal
              itemId={itemId}
              channel={channel}
              openModal={askAQnOverlayOpen}
              headerText="Ask a Question"
              closeModal={() => setAskAQnOverlayOpen(false)}
            />
          </QueryProvider>
        )}
      </>
    )
  );
};

AskAQuestion.displayName = 'AskAQuestion';
