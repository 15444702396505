import React, { useState } from 'react';
import { string, func } from 'prop-types';
import { Col, Row } from '@thd-olt-component-react/grid';
import { Button } from '@one-thd/sui-atomic-components';
import { OverlayPage } from '../../OverlayPage/OverlayPage';
import { Terms } from './Terms';
import { Guidelines } from './Guidelines';

export const Footer = ({ formInputClasses, from, onSubmit }) => {
  const guidelinesText = from === 'question' ? 'Question Guidelines' : 'Answer Guidelines';
  const guidelinesTitle = from === 'question' ? 'Question Writing Guidelines' : 'Answer Writing Guidelines';

  const [overlayPageOpen, setOverlayPageOpen] = useState(false);
  const [overlayPageTitle, setOverlayPageTitle] = useState('');
  const [overlayPageContent, setOverlayPageContent] = useState(null);

  const openOverlayPage = (title, content) => {
    setOverlayPageTitle(title);
    setOverlayPageContent(content);
    setOverlayPageOpen(true);
  };

  const closeOverlayPage = () => {
    setOverlayPageOpen(false);
    setOverlayPageContent(null);
  };

  return (
    <>
      <div className="sui-flex sui-w-full sui-justify-center sui-pb-4">
        <div className={`${formInputClasses}__submit`}>
          <Button onClick={onSubmit} data-testid="AskAQuestionSubmit">Submit</Button>
        </div>
      </div>
      <div>
        <div>
          <div className={`${formInputClasses}__footer__links`}>
            <p>
              {' '}
              By submitting, I agree to the{' '}
              <span
                key="Terms and Conditions"
                className="footer-link"
                onClick={() => openOverlayPage('Terms & Conditions', <Terms />)}
                role="link"
                tabIndex={0}
              >
                Terms and Conditions
              </span>{' '}
              and{' '}
              <span
                key={guidelinesText}
                className="footer-link"
                onClick={() => openOverlayPage(guidelinesTitle, <Guidelines from={from} />)}
                role="link"
                tabIndex={0}
              >
                {guidelinesText}
              </span>
              .
            </p>
          </div>
        </div>
      </div>
      <OverlayPage
        overlayPageTitle={overlayPageTitle}
        OverlayPageContent={overlayPageContent}
        overlayPageOpen={overlayPageOpen}
        closeOverlayPage={closeOverlayPage}
        innerOverlay
      />
    </>
  );
};

Footer.displayName = 'Footer';

Footer.propTypes = {
  formInputClasses: string,
  from: string,
  onSubmit: func,
};

Footer.defaultProps = {
  formInputClasses: null,
  from: 'question',
  onSubmit: null,
};
