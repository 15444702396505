import React from 'react';
import { string, number, bool } from 'prop-types';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Skeleton, SkeletonBlock } from '@one-thd/sui-atomic-components';
import { withHydrator } from '@thd-olt-component-react/hydrator';
import { withDynamicComponent } from '@thd-nucleus/app-render';
import { withErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { QuestionsAndAnswersMobileCard } from './QuestionsAndAnswersMobileCard';
import { QAContextProvider } from '../context/QAContext';
import { dataModel } from './dataModel';

export const QuestionsAndAnswersMobileComponent = ({
  itemId,
  canonicalUrl,
  seoPageNumber,
  enableExperienceProperty
}) => {
  return (
    <QAContextProvider
      itemId={itemId}
      seoPageNumber={seoPageNumber}
      enableExperienceProperty={enableExperienceProperty}
    >
      <QueryProvider cacheKey="question-answer-mobile">
        <QuestionsAndAnswersMobileCard canonicalUrl={canonicalUrl} />
      </QueryProvider>
    </QAContextProvider>
  );
};

QuestionsAndAnswersMobileComponent.displayName = 'QuestionsAndAnswersMobile';

QuestionsAndAnswersMobileComponent.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  seoPageNumber: number,
  enableExperienceProperty: bool
};

QuestionsAndAnswersMobileComponent.defaultProps = {
  canonicalUrl: null,
  seoPageNumber: null,
  enableExperienceProperty: false
};

QuestionsAndAnswersMobileComponent.dataModel = dataModel;

const HydratedComponent = withHydrator({
  delay: 2000,
  id: 'product-section-qa',
  placeholder: (
    <div data-component="QuestionsAndAnswersMobilePlaceholder">
      <Skeleton>
        <SkeletonBlock />
      </Skeleton>
      <Skeleton>
        <SkeletonBlock />
      </Skeleton>
      <Skeleton>
        <SkeletonBlock />
      </Skeleton>
      <Skeleton>
        <SkeletonBlock />
      </Skeleton>
    </div>
  ),
  scrollBuffer: 750
}, QuestionsAndAnswersMobileComponent);

const DynamicComponent = withDynamicComponent(HydratedComponent);

export const QuestionsAndAnswersMobile = withErrorBoundary(DynamicComponent);

QuestionsAndAnswersMobile.propTypes = {
  itemId: string.isRequired,
  canonicalUrl: string,
  seoPageNumber: number,
  enableExperienceProperty: bool
};

QuestionsAndAnswersMobile.defaultProps = {
  canonicalUrl: null,
  seoPageNumber: null,
  enableExperienceProperty: false
};

QuestionsAndAnswersMobile.dataModel = dataModel;