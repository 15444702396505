import { useContext } from 'react';
import { useLazyDataModel } from '@thd-nucleus/data-sources';
import { useStoreId, ExperienceContext } from '@thd-nucleus/experience-context';

export const useUserFeedback = () => {
  const storeId = useStoreId();
  const { deliveryZipCode } = useContext(ExperienceContext);

  const getFeedbackData = (formData) => {
    const visitorId = window?.THDCustomer?.default?.mcvisID;
    return {
      feedback: {
        feedbackComment: '',
        feedbackReason: '',
        modelName: '',
        ...formData,
        storeId,
        visitorId,
        zipCode: deliveryZipCode
      }
    };
  };

  const [submitUserFeedback, response] = useLazyDataModel('captureFeedback', {
    lazy: true
  });

  return [
    submitUserFeedback,
    response,
    getFeedbackData
  ];
};
