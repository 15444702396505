/*  eslint-disable jsx-a11y/control-has-associated-label */
import React, { useContext, useState } from 'react';
import { shape } from 'prop-types';
import { Overlay } from '@thd-olt-component-react/core-ui';
import { CardBody, CardTitle, Typography } from '@one-thd/sui-atomic-components';
import { formatDate } from '../../../helpers/QAHelper';
import { UserProfile } from '../../UserProfile/UserProfile';
import QAContext from '../../../context/QAContext';
import { getHighlightedText } from '../helpers/getHighlightedText';
import { AnswerAQuestion } from '../../AnswerAQuestion/AnswerAQuestion.component';

export const QuestionContainer = ({ result }) => {
  const {
    UserNickname,
    SubmissionId,
    SubmissionTime,
    QuestionSummary,
    QuestionDetails,
    AnswerIds,
    Id,
    AuthorId
  } = result;
  const { searchText } = useContext(QAContext);
  const totalAnswerCount = AnswerIds?.length;
  const totalAnswersText = totalAnswerCount + (totalAnswerCount > 1 ? ' Answers' : ' Answer');

  const question = QuestionDetails || QuestionSummary || '';

  const [showUserOverlay, setShowUserOverlay] = useState(false);

  const handleUserOverlay = (evt) => {
    evt.stopPropagation();
    setShowUserOverlay(!showUserOverlay);
  };

  const handleOverlayEvent = (evt) => {
    evt.stopPropagation();
  };

  return (
    <>
      <div className="sui-w-full">
        <CardBody>
          <CardTitle
            className="sui-w-3/4"
            disableTypography
            header={(
              <Typography
                variant="h5"
                weight="bold"
                height="tight"
              >
                {getHighlightedText(Id, question, searchText)}
              </Typography>
            )}
            data-testid="question-title"
            actionPosition="start"
          />
        </CardBody>
        <div className="sui-text-base sui-mb-4 sui-w-full sui-text-subtle sui-pt-3">
          <span>By</span>
          <button
            type="button"
            onClick={(evt) => handleUserOverlay(evt)}
            className="sui-underline sui-px-1 sui-pb-1"
          >
            {UserNickname},
          </button>
          <span>{formatDate(SubmissionTime)}</span>
        </div>
        <div className="sui-text-base sui-w-full sui-inline-flex">
          {totalAnswersText}&nbsp;|&nbsp;
          <AnswerAQuestion
            question={QuestionDetails || QuestionSummary}
            userName={UserNickname}
            askedDate={formatDate(SubmissionTime)}
            questionId={Id}
          />
        </div>
      </div>
      <div onClick={(evt) => handleOverlayEvent(evt)} role="button" tabIndex={0}>
        <Overlay
          onClose={() => setShowUserOverlay(null)}
          open={showUserOverlay}
          positionedCloseButton
          closeButton
          medium
        >
          <UserProfile
            showUserOverlay={showUserOverlay}
            userNickname={UserNickname}
            authorId={AuthorId}
          />
        </Overlay>
      </div>
    </>
  );
};

QuestionContainer.displayName = 'QuestionContainer';

QuestionContainer.propTypes = {
  result: shape({}),
};

QuestionContainer.defaultProps = {
  result: null,
};
