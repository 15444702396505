import React, { useContext } from 'react';
import { arrayOf, shape, number, bool } from 'prop-types';
import { useConfigService } from '@thd-nucleus/experience-context';
import { Typography, Pill } from '@one-thd/sui-atomic-components';
import { Close } from '@one-thd/sui-icons';
import { Search, Sort } from '../Filter';
import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import { PagerDisplayText } from '../PagerDisplayText/PagerDisplayText.component';
import { BotPrompt } from '../MagicApron/BotPrompt';
import { Header } from './component/Header';
import QAContext from '../../context/QAContext';
import { useQAResult } from './useQAResult';
import { dataModel } from '../dataModel';

export const QAResultHeader = ({ seoResults, seoTotalResults, showHeader }) => {
  const {
    channel, dispatch, TotalResults, searchText, Results, seoAnswers
  } = useContext(QAContext);
  const isMobile = channel === 'mobile';
  const qaResult = useQAResult();
  // hardcoded false to disable magic apron bot inside of accordion
  // not wanted at this time
  const magicApronBotEnabled = false;

  if (!showHeader) return <></>;

  const noResults = !seoAnswers && (TotalResults === 0 || Results?.length === 0);
  const resultsCount = seoResults ? seoTotalResults : TotalResults;

  const handleClearSearchPill = () => {
    dispatch({ action: 'SET_SEARCH_TEXT', value: '' });
  };

  const getMagicApronHeader = (withSearch = false) => {
    return (
      <>
        <BotPrompt isMobile={isMobile} />
        <div className="sui-mt-6 sui-flex sui-flex-wrap">
          <span className="sui-mr-1 sui-mb-2">
            <Typography variant={isMobile ? 'body-base' : 'h2'} weight="bold" component="span">
              {resultsCount + ' '}
            </Typography>
            <Typography variant={isMobile ? 'body-base' : 'body-lg'} component="span">
              Question{resultsCount > 1 ? 's' : ''} Posted By Customers
            </Typography>
          </span>
          {!isMobile && (
            <span>
              <Search />
            </span>
          )}
        </div>
      </>
    );
  };
  return (
    isMobile
      ? (
        <>
          <div>
            {magicApronBotEnabled ? getMagicApronHeader()
              : <Header showTitleWithTotalQns seoData={seoTotalResults} />}
          </div>
          {!qaResult && (
            <>
              <div className="sui-mr-4 sui-w-full">
                <PagerDisplayText />
              </div>
              {searchText && (
                <div className="sui-mb-4 sui-mt-2 sui-w-full">
                  <Pill
                    label={searchText}
                    onClick={handleClearSearchPill}
                    icon={<Close />}
                    data-testid="search-pill"
                  >
                    {Results?.length ? searchText : 'Clear search term'}
                  </Pill>
                </div>
              )}
              <div className="sui-mt-4 sui-w-full">
                <Sort />
              </div>
              {!noResults && <AskAQuestion />}
            </>
          )}
        </>
      )
      : (
        <>
          <div>
            {magicApronBotEnabled ? getMagicApronHeader()
              : <Header showTitleWithTotalQns seoData={seoTotalResults} />}
          </div>
          {!qaResult && (
            <>
              <div className="sui-flex sui-justify-between sui-items-center sui-py-1 sui-mb-4">
                <PagerDisplayText />
                {searchText && (
                  <div className="sui-w-3/6">
                    <Pill
                      label={searchText}
                      onClick={handleClearSearchPill}
                      icon={<Close />}
                      clickable
                    >
                      {Results?.length ? searchText : 'Clear search term'}
                    </Pill>
                  </div>
                )}
                <Sort />
              </div>
              {!noResults && <AskAQuestion />}
            </>
          )}
        </>
      )
  );
};

QAResultHeader.displayName = 'QAResultHeader';

QAResultHeader.propTypes = {
  seoResults: arrayOf(shape({})),
  seoTotalResults: number,
  showHeader: bool
};

QAResultHeader.defaultProps = {
  seoResults: null,
  seoTotalResults: null,
  showHeader: true
};

QAResultHeader.dataModel = dataModel;
