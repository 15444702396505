import React from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { number, string } from 'prop-types';
import { Row } from '@thd-olt-component-react/grid';
import { Vote } from './component/Vote';
import { Report } from './component/Report';

import './Voting.scss';

const Voting = ({ totalPositiveFeedbackCount, answerId }) => {
  return (
    <Row className="sui-flex sui-justify-between sui-items-end">
      <QueryProvider cacheKey="qa-vote">
        <Vote
          answerId={answerId}
          totalPositiveFeedbackCount={totalPositiveFeedbackCount}
        />
        <Report answerId={answerId} />
      </QueryProvider>
    </Row>
  );
};

Voting.displayName = 'Voting';

Voting.propTypes = {
  totalPositiveFeedbackCount: number,
  answerId: string.isRequired
};

Voting.defaultProps = {
  totalPositiveFeedbackCount: 0
};

export { Voting };
