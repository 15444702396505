import React from 'react';
import { bool } from 'prop-types';

import { AskAQuestion } from '../AskAQuestion/AskAQuestion.component';
import './NoContent.scss';

export const NoContent = ({ showNoContent }) => {

  if (!showNoContent) {
    return <></>;
  }
  return (
    <div data-testid="firstQuestion">
      <div>
        <AskAQuestion />
      </div>
      <div>
        <p>Typical questions asked about products:</p>
        <ul className="sui-my-4 sui-ml-4 sui-list-disc">
          <li>Is the product durable?</li>
          <li>Is the product easy to use?</li>
          <li>What are the dimensions of the product?</li>
        </ul>
      </div>
    </div>
  );
};

NoContent.propTypes = {
  showNoContent: bool
};

NoContent.defaultProps = {
  showNoContent: true
};

NoContent.displayName = 'NoContent';
