import React, { useContext, useState, Suspense } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import {
  CardMedia, CardContent, CardBody, CardTitle, Modal, ModalHeader, ModalBody, Typography
} from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { triggerAnalytics } from '../../../Analytics';
import QAContext from '../../../context/QAContext';

export const MediaCarousel = ({ photos, question, answer }) => {
  const [openModal, setOpenModal] = useState(false);
  const [currentMedia, setCurrentMedia] = useState(null);
  const { channel } = useContext(QAContext);

  const isMobile = channel === 'mobile';

  const onOverlayInteraction = () => {
    const payload = {
      origin: 'questions and answers',
      location: 'media',
      value: 'gallery',
      eventType: 'click'
    };
    triggerAnalytics(payload);
  };

  const handleOpen = (Photo) => {
    onOverlayInteraction();
    setCurrentMedia(Photo?.Sizes?.normal?.Url);
    setOpenModal(true);
  };
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <>
      <div>
        <CardContent disablePadding="y">
          <Carousel
            type="hero"
            disableShadow
            hidePaginationOnOneOfOne
          >
            {photos.map((Photo) => (
              <button
                type="button"
                onClick={() => {
                  handleOpen(Photo);
                }}
                data-testid="media-carousel-button"
              >
                <CardMedia
                  src={Photo?.Sizes?.normal?.Url}
                  alt="answer_image"
                  aspect="square"
                  width="fit"
                  key={Photo?.Sizes?.normal?.Url}
                />
              </button>
            ))}
          </Carousel>
        </CardContent>
      </div>

      <Modal open={openModal} onClose={handleClose}>
        <ModalHeader onClose={handleClose} />
        <CardContent>
          <Carousel
            type="hero"
            disableShadow
            hidePaginationOnOneOfOne
          >
            {photos.map((Photo) => (
              <CardMedia
                src={Photo?.Sizes?.normal?.Url}
                width="fit"
              />
            ))}
          </Carousel>
        </CardContent>
      </Modal>
    </>
  );
};

MediaCarousel.displayName = 'MediaCarousel';

MediaCarousel.propTypes = {
  photos: arrayOf(shape({})),
  question: string,
  answer: string
};

MediaCarousel.defaultProps = {
  photos: [],
  question: '',
  answer: ''
};
