import React, { useState, useContext } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { string, shape } from 'prop-types';
import { QAModal } from '../QAModalForm/QAModalForm.component';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';
import './AnswerAQuestion.scss';

export const AnswerAQuestion = ({
  question, userName, askedDate, questionId
}) => {
  const { channel } = useContext(QAContext);
  const buttonText = 'Answer a Question';
  const linkText = 'Answer This Question';
  const [opened, setAnsAQnOverlayOpen] = useState(false);
  const handleOnClick = () => {
    setAnsAQnOverlayOpen(true);

    const payload = { component: 'answer this question' };
    triggerAnalytics(payload);
  };

  return (
    <>
      <div>
        <div className="sui-text-base sui-mb-4">
          <span className="sui-underline" role="link" tabIndex="0" onClick={handleOnClick}>
            {linkText}
          </span>
        </div>
      </div>
      {opened && (
        <QueryProvider cacheKey="answer-a-question-form">
          <QAModal
            openModal={opened}
            closeModal={() => setAnsAQnOverlayOpen(false)}
            headerText={buttonText}
            questionText={question}
            userName={userName}
            askedDate={askedDate}
            channel={channel}
            questionId={questionId}
          />
        </QueryProvider>
      )}
    </>
  );
};

AnswerAQuestion.displayName = 'AnswerAQuestion';

AnswerAQuestion.propTypes = {
  question: string,
  userName: string,
  askedDate: string,
  questionId: string,
};

AnswerAQuestion.defaultProps = {
  question: '',
  userName: '',
  askedDate: '',
  questionId: null,
};
