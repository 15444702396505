import React, { useState, useContext } from 'react';
import { QueryProvider } from '@thd-nucleus/data-sources';
import { Button } from '@one-thd/sui-atomic-components';
import { QAModal } from '../QAModalForm/QAModalForm.component';
import { triggerAnalytics } from '../../Analytics';
import QAContext from '../../context/QAContext';

export const PostAQuestion = () => {
  const { channel, itemId } = useContext(QAContext);

  const [isOverlayOpen, setIsOverlayOpen] = useState(false);

  const onClick = () => {
    setIsOverlayOpen(true);

    const payload = { component: 'ask a new question' };
    triggerAnalytics(payload);
  };

  return (
    <>
      <div className="sui-text-center sui-m-4">
        <div>Didn&apos;t find what you were looking for?</div>
        <Button variant="text" onClick={onClick}>Post a Question</Button>
      </div>
      {isOverlayOpen && (
        <QueryProvider cacheKey="ask-a-question-form">
          <QAModal
            itemId={itemId}
            channel={channel}
            openModal={isOverlayOpen}
            headerText="Ask a Question"
            closeModal={() => setIsOverlayOpen(false)}
          />
        </QueryProvider>
      )}
    </>
  );
};

PostAQuestion.displayName = 'PostAQuestion';
