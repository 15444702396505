import React from 'react';
import { func, string } from 'prop-types';
import { IconButton } from '@one-thd/sui-atomic-components';
import { ThumbsUp, ThumbsDown } from '@one-thd/sui-icons';

export const ThumbsUpThumbsDown = (
  {
    onClick,
    thumbsUpAriaLabel,
    thumbsDownAriaLabel,
    setAnchorEl,
  }) => {
  const handleSelectOption = (option) => {
    onClick(option);
  };

  return (
    <div className="sui-inline-block" role="group">
      <IconButton
        id="tnt_magic_apron_qna_thumbs_up"
        icon={ThumbsUp}
        size="small"
        onClick={() => handleSelectOption('POSITIVE')}
        aria-label={thumbsUpAriaLabel}
        ref={(elem) => setAnchorEl(elem)}
      />
      <IconButton
        id="tnt_magic_apron_qna_thumbs_up"
        icon={ThumbsDown}
        size="small"
        onClick={() => handleSelectOption('NEGATIVE')}
        aria-label={thumbsDownAriaLabel}
        ref={(elem) => setAnchorEl(elem)}
      />
    </div>
  );
};

ThumbsUpThumbsDown.displayName = 'ThumbsUpThumbsDown';

ThumbsUpThumbsDown.propTypes = {
  onClick: func.isRequired,
  thumbsUpAriaLabel: string,
  thumbsDownAriaLabel: string,
  setAnchorEl: func,
};

ThumbsUpThumbsDown.defaultProps = {
  thumbsUpAriaLabel: 'Yes',
  thumbsDownAriaLabel: 'No',
  setAnchorEl: () => {},
};
