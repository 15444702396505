import React, { useEffect, useState } from 'react';
import { string } from 'prop-types';
import { Flag } from '@one-thd/sui-icons';
import { useSubmitReport } from '../../../hooks/index';
import { dataModel } from '../dataModel';
import '../Voting.scss';

const Report = ({ answerId }) => {
  const [reported, setReported] = useState(false);

  const postReportData = {
    id: answerId,
    feedbacktype: 'inappropriate',
    contenttype: 'answer',
    vote: ''
  };

  const [submitReport, reportResponse] = useSubmitReport(postReportData);

  const handleReport = () => {
    submitReport();
  };

  useEffect(() => {
    if (reportResponse?.data) {
      const feedback = reportResponse?.data?.submitReviewFeedback;
      if (feedback?.Feedback?.FeedbackInappropriate) {
        setReported(true);
      }
    }
  }, [reportResponse]);

  return (
    <div className="sui-flex-auto sui-text-right">
      {reported ? (
        <p>Thank you for submitting feedback for this answer!</p>
      ) : (
        <>
          <Flag color="danger" size="small" />
          <button
            className="sui-text-base sui-whitespace-nowrap sui-ml-2 sui-pb-2"
            type="button"
            data-testid="report-button"
            onClick={handleReport}
          >
            Report
          </button>
        </>
      )}
    </div>
  );
};

Report.dataModel = dataModel;

Report.displayName = 'Report';

Report.propTypes = {
  answerId: string.isRequired
};

export { Report };
