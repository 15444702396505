import React, { useContext } from 'react';
import { QAResultContent } from '../QAResultContent/QAResultContent';
import { Pager } from '../Pager/Pager.component';
import { useSEO } from './useSEO';
import QAContext from '../../context/QAContext';
import { useQAResult } from './useQAResult';
import { dataModel } from '../dataModel';
import { QAResultHeader } from './QAResultHeader';

const QAResultDesktop = () => {
  let {
    searchText, Results, TotalResults
  } = useContext(QAContext);

  const qaResult = useQAResult();
  const {
    seoResults, seoAnswers, seoTotalResults, seoPrevPage, seoNextPage
  } = useSEO(qaResult);

  const showResults = !!(Results?.length || seoResults?.length || !searchText);

  return (
    <>
      <QAResultHeader seoTotalResults={seoTotalResults} seoResults={seoResults} />
      {showResults && (
        <div>
          <QAResultContent
            seoResults={seoResults}
            seoAnswers={seoAnswers}
            seoTotalResults={seoTotalResults}
          />
          {Results?.length && !qaResult && (
            <div className="sui-w-full">
              {/* eslint-disable-next-line */}
              <div className="sui-py-6 sui-px-2.5 sui-w-fill">
                {TotalResults > 4 && (
                  <div className="sui-mb-4">
                    <Pager />
                  </div>
                )}
              </div>
            </div>
          )}
          {qaResult && (
            <div>
              <div>
                {seoPrevPage > -1 && <a href={`${seoPrevPage}`}>{`page ${seoPrevPage}`}</a>}{' '}
                {seoNextPage > -1 && <a href={`${seoNextPage}`}>{`page ${seoNextPage}`}</a>}
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

QAResultDesktop.displayName = 'QAResultDesktop';

QAResultDesktop.dataModel = dataModel;

export { QAResultDesktop };
